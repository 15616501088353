<template>
  <div>
    <div :class="`app-header-wrapper menu-wrapper mobile menu-open-${menuOpen}`">
      <div
        v-for="(path, key) in reducedSet"
        class="menu-item"
        :class="`menu-item_${encodeURI(path.fields.prettyName) || 'Home'}`"
        v-if="menuOpen && (!path.fields.hideInMenu && key.split('/').length < 4 || path.fields.isBlog)"
      >
        <router-link :to="key">{{path.fields.prettyName || 'Home'}}</router-link>
      </div>
      <div class="menu-item mobile separator-top">
        <a href="#" @click="switchLang($event)">{{otherLang}}</a>
      </div>
    </div>

    <div
      v-if="data || hideMenu"
      class="fixed-bar"
    >
      <div class="website-wrapper app-header">
        <div class="logo mobile">
          <SensataSplash :no-animate="false"></SensataSplash>
        </div>
        <div class="logo desktop">
          <SensataSplash :no-animate="false" :hide-circle="false"></SensataSplash>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import { TastyBurgerButton } from 'vue-tasty-burgers'
import 'vue-tasty-burgers/dist/vue-tasty-burgers.css'

export default {
  name: 'AppHeader',

  transition: 'page',

  props: [ 'data', 'hide-menu' ],

  data () {
    return {
      menuOpen: false,
      explode: false
    }
  },

  components: {
    SensataSplash: () => import('Components/base/SensataSplash'),
    'tasty-burger-button': TastyBurgerButton
  },

  computed: {
    reducedSet () {
      var newSet = {}
      if (this.$store.state.site.language === 'ES') {
        for (var prop in this.data) {
          if (prop.indexOf('/es') === 0) {
            newSet[prop] = this.data[prop]
          }
        }
      } else {
        for (var prop in this.data) {
          if (prop.indexOf('/es') === -1) {
            newSet[prop] = this.data[prop]
          }
        }
      }
      return newSet
    },
    slug () {
      var root = '/'
      var path = this.$route.params.path
      var subpath = this.$route.params.subpath
      if (path) {
        root = root + this.$route.params.path        
      }
      if (subpath) {
        return root + '/' + subpath
      }
      return root
    },
    otherLang () {
      if (this.$store.state.site.language === 'ES') {
        return 'EN'
      } else {
        return 'ES'
      }
    },
    altSiteCTA () {
      if (this.$store.state.site.language === 'ES') {
        if (process.env.VUE_APP_BASE_URL.indexOf('testeame.com') > -1) {
          return 'Probar el demo »'
        } else {
          return 'Ver los demos »'
        }
      } else {
        if (process.env.VUE_APP_BASE_URL.indexOf('testeame.com') > -1) {
          return 'Probar el demo »'
        } else {
          return 'See the demos »'
        }
      }
    }
  },
  methods: {
    goToLink (target) {
      if (target.indexOf('https://') === 0) {
        window.location.href = target
        return
      }
      this.$router.push(target)
    },
    switchLang (event) {
      event.preventDefault()
      localStorage.setItem('preferred_lang', this.otherLang)

      if (this.$store.state.site.content[this.slug].fields.translationUrl) {
        this.$router.push(this.$store.state.site.content[this.slug].fields.translationUrl)
        return
      } else {
        if (this.$store.state.site.language === 'ES') {
          this.$router.push('/')
        } else {
          this.$router.push('/es')
        }
      }
    },
    toggleMenu () {
      this.menuOpen = !this.menuOpen
    },
    closeExplode () {
      this.explode = !this.explode
    },
    pushHome () {
      if (this.slug.indexOf('/es') === 0) {
        this.$router.push('/es')
      } else {
        this.$router.push('/')
      }
    },
    explodeBubble () {
      this.explode = true
    },
    goToAltSite () {
      if (process.env.VUE_APP_BASE_URL.indexOf('sensata.tk') > -1 || process.env.VUE_APP_BASE_URL.indexOf('sens.la') > -1) {
        window.location.href = "https://www.sensata.io"
      } else {
        window.location.href = "https://testeame.com"
      }      
    }
  },
}

</script>

<style scoped lang="scss">
.fixed-bar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  z-index: 100;
  background: white;
  border-bottom: 1px solid rgba($gray-light, 0.77);
}
.logo {
  max-width: 150px;
  margin-top: 10px;
  &.mobile {
    @include breakpoint($tablet) {
      display: none;
    }
  }
  &.desktop {
    display: none;
    @include breakpoint($tablet) {
      display: block;
    }
  }
}
.app-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: white;
  padding: 10px;
  height: 50px;
  @include breakpoint($tablet) {
    height: 40px;
    padding: 20px;
    justify-content: space-between;
  }
}
.menu-wrapper {
  display: flex;
  &.desktop {
    display: none;
    @include breakpoint($tablet) {
      display: flex;
    }
  }
  .menu-item {
    font-size: 0.5em;
    @include breakpoint($tablet) {
      margin-left: 0.8em;
    }
    a {
      transition: all 0.2s ease-in-out;
      color: $gray-medium;
      text-decoration: none;
      &:hover {
        color: $text-main;
      }
      &.router-link-exact-active,
      &.router-link-active {
        color: $color-wrapper-emphasis-lighter;
      }
    }
    &_Home,
    &_Inicio {
      a {
        &.router-link-active {
          color: $gray-medium;
        }
        &.router-link-exact-active {
          color: $color-wrapper-emphasis-lighter;
        }
      }      
    }
  }
}
.menu-wrapper {
  transition: all 0.2s ease-in-out;
  &.mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0;
    background-color: white;
    opacity: 0.0001;

    &.menu-open-true {
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(221deg, $color-wrapper-emphasis, $color-wrapper-emphasis-lighter) !important;
      background: $gray-light;
      opacity: 1;
      z-index: 1120;
    }

    &.menu-open-false {
      height: 100vh;
      width: 100vw;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(221deg, $color-wrapper-emphasis, $color-wrapper-emphasis-lighter) !important;
      opacity: 0.0001;
    }

    .menu-item {
      font-size: 1.5em;
      line-height: 1.5;
      margin-bottom: 1em;
      a {
        color: white;
        &.router-link-exact-active {
          font-weight: bold;
        }
      }
    }
    @include breakpoint($tablet) {
      display: none;
    }
  }
}
.menu-trigger {
  margin-right: 10px;
  position: fixed;
  right: 6px;
  z-index: 100;
  z-index: 100000;
  top: 26px;
  &.mobile {
    display: block;
    @include breakpoint($tablet) {
      display: none;
    }
  }
  &.hide-true {
    display: none;
  }
}

.satisfaction-wrapper {
  position: fixed;
  z-index: 111;
}

.bubbles-wrapper {
  position: relative;
}

.bubble-content {
  color: white;
  flex: 1;
  display: flex;
  top: 100vh;
  width: 100vw;
  bottom: 0;
  top: 0;
  position: fixed;
  z-index: 1500;
  justify-content: center;
  align-items: center;
}
.bubble-1,
.bubble-2,
.bubble-3 {
  opacity: 0.9;
  position: fixed;
  width: 2em;
  height: 2em;
  border-radius: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  background: #5d2466;
  box-shadow: 0 0 1em $text-main;
  &:hover {
    cursor: pointer;
    transform: scale(1.5);
  }
  &.explode:hover {
    cursor: default;
  }
  transition: all 0.8s ease-in-out;
}
.bubble-1 {
  bottom: -2em;
  right: -2em;
  z-index: 20;
  width: 5em;
  height: 5em;
  @include breakpoint($tablet) {
    width: 3.5em;
    height: 3.5em;
  }
}

.animated-bg-1 {
  background: linear-gradient(221deg, #5d2466, #d9188e);
  background-size: 400% 400%;
  -webkit-animation: CirclingBackground 4s ease infinite;
  -moz-animation: CirclingBackground 4s ease infinite;
  animation: CirclingBackground 4s ease infinite;
}
.animated-bg-2x {
  background: linear-gradient(221deg, #5d2466, #d9188e);
  background-size: 400% 400%;
  -webkit-animation: CirclingBackground 4s ease infinite;
  -moz-animation: CirclingBackground 4s ease infinite;
  animation: CirclingBackground 4s ease infinite;
}

.hide {
  opacity: 0.0001;
}
.explode {
  width: 250vh;
  height: 250vh;
  transform: scale(2);
  animation-duration: 0;
  box-shadow: 0 0 0 transparent;
}
.separator-top {
  border-top: 1px solid rgba(white, 0.6);
  width: 50%;
  padding-top: 1em;
}
.desktop-lang-switch {
  background: $color-wrapper-emphasis-lighter;
  transition: background 0.2s ease-in-out;
  padding: 0 0.3em;
  a {
    color: white !important;
  }
  &:hover {
    background-color: $color-wrapper-emphasis;
  }
}
.text-as-link {
  font-family: "Montserrat", Avenir, sans-serif;
  h2 {
    @include breakpoint($tablet) {
      font-size: 0.8em;
      font-weight: normal;
    }
  }
}
</style>

<style lang="scss">
.hamburger-inner, .hamburger-inner__before, .hamburger-inner__after {
  height: 2px !important;
  width: 35px;
}
.cta-button.el-button.is-round.app-header-button {
  font-family: 'Avenir', 'Nunito', sans-serif;
  transition: all 0.4s ease-in-out;
  font-size: 16px;
  background: $text-main;
  border-color: $text-main;
  color: white;
  @include breakpoint($tablet) {
    font-size: 20px;
    border-radius: 200px;
    &:hover {
      background: white;
      border-color: white;
      color: $text-main;
    }
  }
}

</style>